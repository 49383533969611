import * as React from "react"
import Layout from "../../components/layout"
import Contact from "../../components/sections/contact"
import AboutGuides from "../../components/sections/index/about_guides"
import AboutSysphar from "../../components/sections/index/about_sysphar"
import AboutWiki from "../../components/sections/index/about_wiki"
import CarouselProjects from "../../components/sections/index/carousel_projects"
import Welcome from "../../components/sections/index/welcome"
import Seo from "../../components/seo"

const IndexPage = props => (
  <Layout language="en" pathname={props.location.pathname}>
    <Seo
      title="Home"
      lang="en"
      description="Hello, we are CAMARADES Brazil! Our website offers useful tools for your systematic literature review."
    />
    <Welcome language="en" />
    <CarouselProjects language="en" />
    <AboutGuides language="en" />
    <AboutWiki language="en" />
    <AboutSysphar language="en" />
    <Contact language="en" />
  </Layout>
)

export default IndexPage
